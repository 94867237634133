'use strict';

$(document).ready(initPage);
function initPage() {
  function checkScroll() {
    if ($(window).scrollTop() > 0) {
      $('body').addClass('is-scrolled');
    } else {
      $('body').removeClass('is-scrolled');
    }
  }
  checkScroll();
  $(window).scroll(function () {
    checkScroll();
  });
  $('.toggler').click(function () {
    $('body').toggleClass('is-menu');
  });
  const swiper = new Swiper('.swiper--slider', {
    slidesPerView: 'auto',
    loop: true,
    spaceBetween: 46,
    centeredSlides: true,
    autoplay: true,
    breakpoints: {
      320: {
        spaceBetween: 20,
      },
      1024: {
        spaceBetween: 30,
      },
      1600: {
        spaceBetween: 46,
      },
    },
  });

  /*** Animations ***/
  function isElementInViewport(el) {
    var elementTop = $(el).offset().top;
    var elementBottom = elementTop + $(el).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
  }

  function checkVisibility() {
    $('[data-aos]').each(function () {
      var $this = $(this);
      if (isElementInViewport($this) && !$this.hasClass('is-active')) {
        var delay = $this.data('aos-delay') || 0;
        setTimeout(function () {
          $this.addClass('is-aos');
        }, delay);
      }
    });
  }

  checkVisibility();

  $(window).on('scroll', function () {
    checkVisibility();
  });
}
